import React from 'react'
import { Container } from 'react-bootstrap'
import Logo from '../Resourses/footer-logo-rami.svg'
import IconFacebook from '../Resourses/footer-icon-facebook.svg'
import IconInstagram from '../Resourses/footer-icon-instagram.svg'
import IconWhatsApp from '../Resourses/footer-icon-whatsapp.svg'
import IconLinkedin from '../Resourses/footer-icon-linkedin.svg'
import IconWhatsAppLight from '../Resourses/footer-icon-whatsap-outline.svg'

const Footer = () => {
  return (
    <footer className='p-0 m-0' >
      <Container className='footerContainer' >
        <div className="container text-center m-0 p-0 ">
          <div className="row">
            
            <div className="co-12l col-md-3 col-lg-3  footer__items  footer__item--center">
              <img src={Logo} className='footer__logo' alt="Rami Cintas + Hilos" />
            </div>
            
            <div className="col-12 col-md-3 footer__items">
              <p className='footer__texto'> 
                Pedro Suarez 928 (1842)<br />
                <strong>Monte Grande Pcia. Bs.As</strong><br />
                4290 - 9666 / 9494<br />
              </p>
              <div className='row p-0 footer__redes'>
                
                <div className="col-3 col-md-2 col-lg-2">
                  <a href="https://www.facebook.com/CasaRamiSRL" target={'_blank'} rel="noreferrer" style={{ cursor: 'pointer'}}>
                    <img src={IconFacebook} className='footer__icon' alt="Rami Cintas Facebook" />
                  </a> 
                </div>

                <div className="col-3 col-md-2 col-lg-2">
                  <a href="https://www.instagram.com/rami_cintasadhesivas/" target={'_blank'} rel="noreferrer" style={{ cursor: 'pointer'}}>
                    <img src={IconInstagram} className='footer__icon' alt="Rami Cintas Instagram" />
                  </a>
                </div>
                
                <div className="col-3 col-md-2 col-lg-2">
                  <a href="https://www.linkedin.com/company/69259856/admin/dashboard/" target={'_blank'} rel="noreferrer"  style={{ cursor: 'pointer'}}>
                    <img src={IconLinkedin} className='footer__icon' alt="Rami Cintas Linkedin" />
                  </a>
                </div>
                
                  <div className="col-3 col-md-2 col-lg-2">
                    <a href="https://wa.me/5491126273894" target={'_blank'} rel="noreferrer" style={{ cursor: 'pointer'}}>
                      <img src={IconWhatsApp} className='footer__icon' alt="Rami Cintas WhatsApp" />
                    </a>
                  </div>
               

              </div>
              <div className='row footer__venta'>
                <div className='col-2'>
                  <img src={IconWhatsAppLight} className='footer__icon' alt="Rami Cintas WhatsApp+ Hilos" />
                </div>
                <div className='col-10'>
                  <span className='footer__venta--texto'>VENTA MAYORISTA</span>
                  <span className='footer__venta--numero'>011 2627 3894</span>
                </div>
              </div>
            </div>
            
            <div className="col-12 col-md-6 col-lg-6 p-0 m-0">
              <img src="./images/footer-trabajador.jpg" alt="Trabaja con nostros" className='d-block w-100 p-0'/>
            </div>

          </div>
        </div>  
      </Container>
    </footer>
  )
}

export default Footer