import { Container } from 'react-bootstrap'

import NavbarPages from '../NavbarPages'
import HeaderPages from '../HeaderPages'

const imgHeader = "./images/productos//header-productos-norte.jpg";
const imgThumbIzq = "./images/productos/productos_thumbs--norte.jpg";
const altText = "Logo de productos NORTE";

const tipoColor = 'productos__detail--tipoNorte'

const detailLine1 = "PRODUCTOS";
// const detailLine2 = "ADHESIVA";
const detailLine3 = "NORTE";

const imgTable1 ="./images/productos/norte/cuadro-norte-1-libretas-sin-indice.jpg";
const imgTable2 ="./images/productos/norte/cuadro-norte-2-libretas-con-indice.jpg";
const imgTable3 ="./images/productos/norte/cuadro-norte-3-cuadernos-sin-indice.jpg";
const imgTable4 ="./images/productos/norte/cuadro-norte-4-cuadernos-con-indice.jpg";


const textoDetail1 = 'Desde hace más de 15 años, somos distribuidores de productos NORTE,'
const textoDetail2 = 'Cooperativa de Trabajo Norte, una tradicional empresa, creadora de las clásicas agendas y cuadernos con espiral entre otros productos, familiarmente utilizados en la oficina, el comercio y la educación.'

const imgThumb1 = './images/productos/mit/abrochadoras/abrochadoras-1.jpg';
const imgThumb1Alt = 'Cintas de seguridad.';


const ProductosNorte = () => {
  return (
    <Container className='mb-0'>
        <NavbarPages className="p-0" />
        <div className='row'>
          <div className='col-12 mb-4'>
            <HeaderPages img={imgHeader} alt={"Productos Kive Cintas"} />
          </div>
          <div className='col-12 col-sm-6 col-md-3 productos__imgContainer'>
            <img 
              src={imgThumbIzq} 
              alt={altText} 
              className='d-block w-100 productos__imgContainer--img' 
              loading='lazy'/>
          </div>
          <div className='col-12 col-sm-12 col-md-9 productos__vert--int mb-4 mt-1'>
            <div className='row productos__detail'>
              <div className='col-12'>
                <p className={`productos__detail--tipo mt-3 ${tipoColor}`}>{detailLine1}</p>
                <p className={`productos__detail--caracteristica ${tipoColor}`}>{detailLine3}</p>
                <p className='site_text productos__text--int mb-0 pt-2'>{textoDetail1}<br />{textoDetail2}</p>
              </div>
            </div>
          </div>
          <div className='row  justify-content-center'>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-6">
                <img  src={imgTable1} 
                    className='d-block w-100' 
                    alt={altText}
                    loading="lazy" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-6">
                <img  src={imgTable2} 
                    className='d-block w-100' 
                    alt={altText}
                    loading="lazy" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-6">
                <img  src={imgTable3} 
                    className='d-block w-100' 
                    alt={altText}
                    loading="lazy" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-6">
                <img  src={imgTable4} 
                    className='d-block w-100' 
                    alt={altText}
                    loading="lazy" />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-6 col-sm-6 col-md-6 col-lg-3 mb-4' >
              <p class="productos__detail--norte">Libretas con/sin índice</p>
              <img src="./images/productos/norte/producto-norte-1.jpg" className='d-block w-100 p-0 m-0 ' alt={ imgThumb1Alt} loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-3 mb-4">
                <p class="productos__detail--norte"><br /></p>
                <img src="./images/productos/norte/producto-norte-2.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-3 mb-4">
                <p class="productos__detail--norte"><br /></p>
                <img src="./images/productos/norte/producto-norte-3.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-3 mb-4">
                <p class="productos__detail--norte"><br /></p>
                <img src="./images/productos/norte/producto-norte-4.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
           
        </div>
        <div className='row '>
          <div className='col-6 col-sm-6 col-md-6 col-lg-3 mb-4' >
                <p class="productos__detail--norte"><br /></p>
              <img src="./images/productos/norte/producto-norte-5.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-3 mb-4">
                <p class="productos__detail--norte"><br /></p>
                <img src="./images/productos/norte/producto-norte-6.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-3 mb-4">
                <p class="productos__detail--norte"><br /></p>
                <img src="./images/productos/norte/producto-norte-7.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-3 mb-4">
                <p class="productos__detail--norte"><br /></p>
                <img src="./images/productos/norte/producto-norte-8.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-3 mb-4">
                <p class="productos__detail--norte"><br /></p>
                <img src="./images/productos/norte/producto-norte-9.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-3 mb-4">
                <p class="productos__detail--norte"><br /></p>
                <img src="./images/productos/norte/producto-norte-10.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-3 mb-4">
                <p class="productos__detail--norte">Cuadernos con/sin índice</p>
                <img src="./images/productos/norte/producto-norte-11.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-3 mb-4">
            <p class="productos__detail--norte"><br/></p>
                <img src="./images/productos/norte/producto-norte-12.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
        </div>
        <div className='row'>
            <div className='col-6 col-sm-6 col-md-6 col-lg-3 mb-4' >
                <p class="productos__detail--norte"><br /></p>
                <img src="./images/productos/norte/producto-norte-13.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-3 mb-4">
                <p class="productos__detail--norte"><br /></p>
                <img src="./images/productos/norte/producto-norte-14.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-3 mb-4">
                <p class="productos__detail--norte"><br /></p>
                <img src="./images/productos/norte/producto-norte-15.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
        </div>           
      </Container>
    
  )
}

export default ProductosNorte