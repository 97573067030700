import { Container } from 'react-bootstrap'

import NavbarPages from '../NavbarPages'
import HeaderPages from '../HeaderPages'
import KiveUso from '../../../Resourses/products/kive-uso-comercial-grafico-escolar.svg'

const imgHeader = "./images/productos/header-productos-kive-doble-faz.jpg";
const imgThumbIzq = "./images/productos/productos_thumbs--cinta-adhesiva-doble-faz-kive.jpg";
const altText = "CINTA ADHESIVA DOBLE FAZ ESPUMADA";

const tipoColor = 'productos__detail--tipoDobleFazKive'

const detailLine1 = "CINTAS";
const detailLine2 = "ADHESIVAS";
const detailLine3 = "DOBLE FAZ";

const imgTable ="./images/productos/kive/cuadro-kive-cinta-doble-faz.jpg"; 

const textoDetail1 = 'Cintas adhesivadas en ambas caras, de alta adherencia, separadas por un papel siliconado fácil de remover.'
const textoDetail2 = 'Se utilizan para el cierre de sobres, fijación decorativa, montajes livianos, pegado y montaje de polímeros. Muy prácticas y esenciales para los trabajos que realizan los estudiantes de arquitectura y diseño.'

const imgThumb1 = './images/productos/kive-cinta-adhesiva-doble-faz/kive-cinta-adhesiva-doble-faz-1.jpg';
const imgThumb1Alt = 'Cinta de papel 18mm';

const imgThumb2 = './images/productos/kive-cinta-adhesiva-doble-faz/kive-cinta-adhesiva-doble-faz-2.jpg';
const imgThumb2Alt = 'CINTA de papel 24mm';

const imgThumb3 = './images/productos/kive-cinta-adhesiva-doble-faz/kive-cinta-adhesiva-doble-faz-3.jpg';
const imgThumb3Alt = 'CINTA de papel a granel.';



const CintaAdhesivaDobleFaz = () => {
  return (
    <Container className='mb-0'>
        <NavbarPages className="p-0" />
        <div className='row'>
          <div className='col-12 mb-4'>
            <HeaderPages img={imgHeader} alt={"Productos Kive Cintas"} />
          </div>
          <div className='col-12 col-sm-6 col-md-5 productos__imgContainer'>
            <img 
              src={imgThumbIzq} 
              alt={altText} 
              className='d-block w-100 productos__imgContainer--img' 
              loading='lazy'/>
          </div>
          <div className='col-12 col-sm-12 col-md-7 productos__vert--int mb-4 mt-1'>
            <div className='row productos__detail'>
              <div className='col-12'>
                <p className={`productos__detail--tipo ${tipoColor} mb-0`}>{detailLine1}
                 <span className={`productos__detail--caracteristica ${tipoColor}`}> {detailLine2}</span>
                </p>
                <p className={`productos__detail--caracteristica ${tipoColor}`}>{detailLine3}</p>
                <img src={KiveUso} className='d-block productos__detail--svg' alt='' loading="lazy" />
              </div>
              <div className='col-12 productos__detail'>
                <img  src={imgTable} 
                      className='d-block w-100' 
                      alt={altText}
                      loading="lazy" />
              </div>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-12 productos__vert mb-4 mt-1'>
            <p className='site_text productos__text--int mb-0'> 
              {textoDetail1}<br/>
              {textoDetail2}
            </p>
          </div>
        </div>
        <div className='row'>
        
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb1} className='d-block w-100 p-0 m-0 ' alt={ imgThumb1Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb2} className='d-block w-100 p-0 m-0 ' alt={ imgThumb2Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb3} className='d-block w-100 p-0 m-0 ' alt={ imgThumb3Alt} loading="lazy" />
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-12">
              <img src="./images/productos/kive-cinta-adhesiva-doble-faz/kive-cinta-adhesiva-doble-faz-4.jpg" class="d-block w-100 p-0 m-0 " alt="kive-cinta-adhesiva-doble-faz-tubo" loading="lazy" />
            </div>
            <div>
              <p class="site_text productos__text--int mb-0 mt-4"><strong>NUEVO TUBO DOBLE FAZ </strong> 
              de excelente fijación, imprescindible para el uso en colocación de corpóreos y cartelería.<br />
              <strong>Presentaciones:</strong> 1040 mm y 30 m <strong>x</strong> 1260 mm y 30 mm. Vienen 2 tubos por caja.</p>
            </div>
        </div>       
      </Container>
    
  )
}

export default CintaAdhesivaDobleFaz