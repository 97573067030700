import { Container } from 'react-bootstrap'

import NavbarPages from '../NavbarPages'
import HeaderPages from '../HeaderPages'

const imgHeader = "./images/productos/header-productos-mit.jpg";
const imgThumbIzq = "./images/productos/productos_thumbs--mit.jpg";
const altText = "CINTA DE EMPAQUE";

const tipoColor = 'productos__detail--tipoMit'

const detailLine1 = "PRODUCTOS";
// const detailLine2 = "ADHESIVA";
const detailLine3 = "MIT";

const imgTableIzq ="./images/productos/mit/cuadro-mit.jpg";
const imgTableDer ="./images/productos/mit/cuadro-mit-largo.jpg";


const textoDetail1 = 'Somos distribuidores de productos MIT desde hace más de 15 años,'
const textoDetail2 = 'MIT es la empresa que lidera, desde hace más de 50 años, la fabricación y distribución de abrochadoras, perforadores y otros productos para escritorio, tanto para su utilización en la oficina como en la industria, el comercio y el hogar.'

const imgThumb1 = './images/productos/mit/abrochadoras/abrochadoras-1.jpg';
const imgThumb1Alt = 'Cintas de seguridad.';


const ProductosMit = () => {
  return (
    <Container className='mb-0'>
        <NavbarPages className="p-0" />
        <div className='row'>
          <div className='col-12 mb-4'>
            <HeaderPages img={imgHeader} alt={"Productos MIT"} />
          </div>
          <div className='col-12 col-sm-6 col-md-3 productos__imgContainer'>
            <img 
              src={imgThumbIzq} 
              alt={altText} 
              className='d-block w-100 productos__imgContainer--img' 
              loading='lazy'/>
          </div>
          <div className='col-12 col-sm-12 col-md-9 productos__vert--int mb-4 mt-1'>
            <div className='row productos__detail'>
              <div className='col-12'>
                <p className={`productos__detail--tipo mt-3 ${tipoColor}`}>{detailLine1}</p>
                <p className={`productos__detail--caracteristica ${tipoColor}`}>{detailLine3}</p>
                <p className='site_text productos__text--int mb-0 pt-2'>{textoDetail1}<br /><br />{textoDetail2}</p>
              </div>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-12 productos__vert mb-4 mt-1 justify-content-md-center'>
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 mb-5 mb-auto">
              <img  src={imgTableIzq} 
                    className='d-block w-100' 
                    alt={altText}
                    loading="lazy" />
            </div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 mb-5 mb-auto">
              <img  src={imgTableDer} 
                    className='d-block w-100' 
                    alt={altText}
                    loading="lazy" />
            </div>
          </div>
        </div>
        <div className='row  justify-content-center'>
          <div className='col-6 col-sm-6 col-md-6 col-lg-2 mb-4' >
              <p class="productos__detail--mit">Abrochadoras</p>
              <img src={imgThumb1} className='d-block w-100 p-0 m-0 ' alt={ imgThumb1Alt} loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-2 mb-4">
                <p class="productos__detail--mit"><br /></p>
                <img src="./images/productos/mit/abrochadoras/abrochadoras-2.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-2 mb-4">
                <p class="productos__detail--mit"><br /></p>
                <img src="./images/productos/mit/abrochadoras/abrochadoras-3.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-2 mb-4">
                <p class="productos__detail--mit"><br /></p>
                <img src="./images/productos/mit/abrochadoras/abrochadoras-4.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-2 mb-4">
                <p class="productos__detail--mit"><br /></p>
                <img src="./images/productos/mit/abrochadoras/abrochadoras-5.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
        </div>
        <div className='row  justify-content-center'>
          <div className='col-6 col-sm-6 col-md-6 col-lg-2 mb-4' >
              <p class="productos__detail--mit">Mini Abrochadoras</p>
              <img src='./images/productos/mit/mini-abrochadoras/mini-abrochadoras-1.jpg' className='d-block w-100 p-0 m-0 ' alt={ imgThumb1Alt} loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-2 mb-4">
                <p class="productos__detail--mit"><br /></p>
                <img src="./images/productos/mit/mini-abrochadoras/mini-abrochadoras-2.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-2 mb-4">
                <p class="productos__detail--mit"><br /></p>
                <img src="./images/productos/mit/mini-abrochadoras/mini-abrochadoras-3.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-2 mb-4">
            <p class="productos__detail--mit">Sacabroches</p>
                <img src="./images/productos/mit/sacabroches/sacabroches.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-2 mb-4">
                <p class="productos__detail--mit">Mini sacabroches</p>
                <img src="./images/productos/mit/mini-sacaborches/mini-sacaborches.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
        </div>
        <div className='row  justify-content-center'>
          <div className='col-6 col-sm-6 col-md-6 col-lg-2 mb-4' >
              <p class="productos__detail--mit">Perforadores</p>
              <img src='./images/productos/mit/perforadora/perforadora-1.jpg' className='d-block w-100 p-0 m-0 ' alt={ imgThumb1Alt} loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-2 mb-4">
                <p class="productos__detail--mit"><br /></p>
                <img src="./images/productos/mit/perforadora/perforadora-2.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-2 mb-4">
                <p class="productos__detail--mit"><br /></p>
                <img src="./images/productos/mit/perforadora/perforadora-3.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-2 mb-4">
                <p class="productos__detail--mit">Broches</p>
                <img src="./images/productos/mit/broches/broches-1.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
            <div class="col-6 col-sm-6 col-md-6 col-lg-2 mb-4">
                <p class="productos__detail--mit"><br /></p>
                <img src="./images/productos/mit/broches/broches-2.jpg" class="d-block w-100 p-0 m-0 " alt="Abrochadoras-mit" loading="lazy" />
            </div>
        </div>           
      </Container>
    
  )
}

export default ProductosMit