import { Container } from 'react-bootstrap'

import NavbarPages from '../NavbarPages'
import HeaderPages from '../HeaderPages'
import KiveUso from '../../../Resourses/products/rami-uso-artesanal-embalajes-domestico.svg'

const imgHeader = "./images/productos/header-internas-rami-hilo-algodon-crudo.jpg";
const imgThumbIzq = "./images/productos/productos_thumbs--hilo-algodon-crudo-rami.jpg";
const altText = "CINTA DE EMPAQUE";

const tipoColor = 'productos__detail--tipoHiloAlgodon'

const detailLine1 = "HILOS DE";
// const detailLine2 = "ADHESIVA";
const detailLine3 = "ALGODON CRUDO";

const imgTable ="./images/productos/rami/cuadro-rami-hilo-algodon-crudo.jpg";


const textoDetail1 = 'Hilo de algodón en color blanco, tiene varios usos recomendados como : decoración, remontar barriletes, atar globos, manualidades, tejido, etc.'
const textoDetail2 = 'Vienen en ovillos y/o bobinas.'

const imgThumb1 = './images/productos/rami-hilo-algodon-crudo/rami-hilo-algodon-crudo-1.jpg';
const imgThumb1Alt = 'Hilo algodon crudo.';

const imgThumb2 = './images/productos/rami-hilo-algodon-crudo/rami-hilo-algodon-crudo-2.jpg';
const imgThumb2Alt = 'Hilo algodon crudo.';

const imgThumb3 = './images/productos/rami-hilo-algodon-crudo/rami-hilo-algodon-crudo-3.jpg';
const imgThumb3Alt = 'Hilo algodon crudo.';

const HilosAlgodonCrudoRami = () => {
  return (
    <Container className='mb-0'>
        <NavbarPages className="p-0" />
        <div className='row'>
          <div className='col-12 mb-4'>
            <HeaderPages img={imgHeader} alt={"Productos Kive Cintas"} />
          </div>
          <div className='col-12 col-sm-6 col-md-5 productos__imgContainer'>
            <img 
              src={imgThumbIzq} 
              alt={altText} 
              className='d-block w-100 productos__imgContainer--img' 
              loading='lazy'/>
          </div>
          <div className='col-12 col-sm-12 col-md-7 productos__vert--int mb-4 mt-1'>
            <div className='row productos__detail'>
              <div className='col-12'>
                <p className={`productos__detail--caracteristica productos__detail--tipo ${tipoColor}`}>{detailLine1}
                 {/* <span className={`productos__detail--caracteristica ${tipoColor}`}> {detailLine2}</span> */}
                </p>
                <p className={`productos__detail--caracteristica ${tipoColor}`}>{detailLine3}</p>
                <img src={KiveUso} className='d-block productos__detail--svg' alt='' loading="lazy" />
                </div>
                <div className='col-12 productos__detail'>
                    <img  src={imgTable} 
                        className='d-block w-100' 
                        alt={altText}
                        loading="lazy" />
                </div>
                <p className='site_text productos__text--int mb-0'> 
                    {textoDetail1}<br/><br/>
                    {textoDetail2}<br/><br/>
                </p>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-12 productos__vert mb-4 mt-1'>
            
          </div>
        </div>
        <div className='row'>
        
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb1} className='d-block w-100 p-0 m-0 ' alt={ imgThumb1Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb2} className='d-block w-100 p-0 m-0 ' alt={ imgThumb2Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb3} className='d-block w-100 p-0 m-0 ' alt={ imgThumb3Alt} loading="lazy" />
            </div>
           
        </div>       
      </Container>
    
  )
}

export default HilosAlgodonCrudoRami