import { Container } from 'react-bootstrap'

import NavbarPages from '../NavbarPages'
import HeaderPages from '../HeaderPages'
import KiveUso from '../../../Resourses/products/rami-uso-hilo-sisal-artesala-decorativo.svg'

const imgHeader = "./images/productos/header-internas-rami-sisal-yute.jpg";
const imgThumbIzq = "./images/productos/productos_thumbs--hilo-sisal-rami.jpg";
const altText = "HILOS SISAL/YUTE";

const tipoColor = 'productos__detail--tipoHiloSisal'

const detailLine1 = "HILOS";
// const detailLine2 = "ADHESIVA";
const detailLine3 = "SISAL/YUTE";

const imgTable ="./images/productos/rami/cuadro-rami-hilo-sisal-1.jpg";

const textoDetail = 'Hilo elaborado con fibra de sisal natural de primera calidad.'
const textoDetail1 = 'Utilizado básicamente en manualidades, tejidos, artesanías (lazos, frascos, lámparas, souvenirs, decoraciones, centros de mesas etc.)'

const imgThumb1 = './images/productos/rami-hilo-sisal/rami-hilo-sisal-1.jpg';
const imgThumb1Alt = 'Hilo Sisal.';

const imgThumb2 = './images/productos/rami-hilo-sisal/rami-hilo-sisal-2.jpg';
const imgThumb2Alt = 'Hilo Sisal.';

const imgThumb3 = './images/productos/rami-hilo-sisal/rami-hilo-sisal-3.jpg';
const imgThumb3Alt = 'Hilo Sisal de colores.';

const HilosSisalRami = () => {
  return (
    <Container className='mb-0'>
        <NavbarPages className="p-0" />
        <div className='row'>
          <div className='col-12 mb-4'>
            <HeaderPages img={imgHeader} alt={"Productos Kive Cintas"} />
          </div>
          <div className='col-12 col-sm-6 col-md-5 productos__imgContainer'>
            <img 
              src={imgThumbIzq} 
              alt={altText} 
              className='d-block w-100 productos__imgContainer--img' 
              loading='lazy'/>
          </div>
          <div className='col-12 col-sm-12 col-md-7 productos__vert--int mb-4 mt-1'>
            <div className='row productos__detail'>
              <div className='col-12'>
                <p className={`productos__detail--caracteristica productos__detail--tipo ${tipoColor}`}>{detailLine1}
                 {/* <span className={`productos__detail--caracteristica ${tipoColor}`}> {detailLine2}</span> */}
                </p>
                <p className={`productos__detail--caracteristica ${tipoColor}`}>{detailLine3}</p>
                <img src={KiveUso} className='d-block productos__detail--svg' alt='' loading="lazy" />
                </div>
                <div className='col-12 productos__detail'>
                    <img  src={imgTable} 
                        className='d-block w-100' 
                        alt={altText}
                        loading="lazy" />
                </div>
                <p className='site_text productos__text--int mb-0'> 
                  {textoDetail}<br/><br/>
                </p>
                <p className='site_text productos__text--int mt-1'> 
                  {textoDetail1}
                </p>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-12 productos__vert mb-4 mt-1'>
            
          </div>
        </div>
        <div className='row'>
        
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb1} className='d-block w-100 p-0 m-0 ' alt={ imgThumb1Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb2} className='d-block w-100 p-0 m-0 ' alt={ imgThumb2Alt} loading="lazy" />
            </div>
            <div className='col-12 col-sm-12 col-md-6 col-lg-4 mb-4' >
              <img src={imgThumb3} className='d-block w-100 p-0 m-0 ' alt={ imgThumb3Alt} loading="lazy" />
            </div>
           
        </div>       
      </Container>
    
  )
}

export default HilosSisalRami